import Vue from "vue";
import VueRouter from "vue-router";
import utils from "@/common/utils";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: (to) => {
      return {
        path: "/login",
        query: to.query,
      };
    },
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "租后调度",
      anonymous: true,
    },
    component: () => import("../views/login/Index.vue"),
  },
  {
    path: "/home",
    name: "Home",
    meta: {
      title: "租后调度",
      anonymous: true,
    },
    component: () => import("../views/home/Index.vue"),
  },
  {
    path: "/custom",
    name: "Custom",
    meta: {
      title: "客户信息",
      anonymous: true,
    },
    component: () => import("../views/custom/Index.vue"),
  },
  {
    path: "/trial",
    name: "Trial",
    meta: {
      title: "结清试算",
      anonymous: true,
    },
    component: () => import("../views/trial/Index.vue"),
  },
  {
    path: "/caseDispatch",
    name: "CaseDispatch",
    meta: {
      title: "案件分派",
      anonymous: true,
    },
    component: () => import("../views/caseDispatch/Index.vue"),
  },
  {
    path: "/caseAudit",
    name: "CaseAudit",
    meta: {
      title: "认证审核",
      anonymous: true,
    },
    component: () => import("../views/caseAudit/Index.vue"),
  },
  {
    path: "/annex",
    name: "Annex",
    meta: {
      title: "法诉附件",
      anonymous: true,
    },
    component: () => import("../views/annex/Index.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  to.meta && to.meta.title && utils.setTitle(to.meta.title);
  if (to.meta && to.meta.anonymous) {
    return next();
  }
  const { info } = store.getters;
  if (!info) {
    const query = {
      redirect_path: to.path,
    };
    return next({ name: "Login", query });
  }
  next();
});

export default router;
