const ua = window.navigator.userAgent;
const isIOS = ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
const isWechat = ua.indexOf("MicroMessenger") > -1;
const isAndroid = ua.match(/(Android)\s+([\d.]+)/);
const ipad = ua.match(/(iPad).*OS\s([\d_]+)/);
const isMobile = !!isIOS || !!isAndroid || !!ipad;

import moment from "moment";

moment.locale("zh-cn");

/**
 * 日期格式化
 * @description 基于 moment 的日期格式化
 * @param {Number|String|Date} value
 * @param {String} format 更多配置参考 moment 文档 <https://momentjs.com/docs/>
 * @param {String} unlimitedText
 * @returns String
 */
export const dateFormat = (
  value,
  format = "YYYY-MM-DD HH:mm:ss",
  unlimitedText = ""
) => {
  if (!value) return "";
  // eslint-disable-next-line
  value = typeof value === "string" ? value.replace(/\-/g, "/") : value;
  let date = new Date(value);
  if (Number.isNaN(date.getTime())) return "";
  if (date.getFullYear() === 9999) return unlimitedText;
  return moment(date).format(format);
};

// 比较数组中的值，找出最大数值
export const getMaxInArray = (array) => {
  if (!array || !Array.isArray(array)) return null;
  // 或者：Math.max(...array);
  return array.reduce((max, set) => {
    const _set = parseFloat(set) || 0;
    return _set >= max ? _set : max;
  }, 0);
};

// 获取某个数值的接近的整十、整百、整千等数值
export const numAlign = (num) => {
  if (!num) return 0;
  let _round = Math.ceil(parseInt(num.toString().split(".")[0]) / 2);
  _round = +_round
    .toString()
    .split("")
    .map((set, index) => (index === 0 ? 1 : 0))
    .join("");
  return Math.ceil(num / _round) * _round;
};

/**
 * 判断增量符号
 *
 * @param {*} v
 * @returns
 */
export const detectIncrementSymbol = (v) => {
  v = parseFloat(v || 0);
  return v > 0 ? "↑" : v < 0 ? "↓" : "-";
};

/**
 * 简化地域名称
 *
 * @param {*} name
 * @returns
 */
export const simplifyRegionName = (name) => {
  return name.replace(
    /省|市|壮族自治区|回族自治区|维吾尔自治区|自治区|特别行政区/,
    ""
  );
};

// 比率修正
export const columnRatio = (value, placeholder = "-") => {
  if (value === null || isNaN(value)) return placeholder;
  value = Number(value).toFixed(2);
  return parseFloat(value);
};

/**
 * 千分位数字格式化
 * @param {Number|String} value
 * @param {int} fixed 修正位数
 * @returns {null|String}
 */
export const amount = (value, fixed = 2) => {
  if (isNaN(value)) return null;
  if ([null, undefined, ""].includes(value)) return null;
  if (value === 0) return parseFloat(0).toFixed(fixed);
  let num = value.toString().replace(/,/g, "");
  num = parseFloat(num || 0);
  num = num.toFixed(+fixed || 0).toString();
  num = num.split(".");
  if (num.length === 1) return num[0].replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
  return [num[0].replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"), num[1]].join(".");
};

/**
 * 单元格金额处理
 * @param {Number|String} value
 * @param {int} placeholder 值为空时的占位符
 * @param {int} fixed 修正位数
 * @returns String
 */
export const columnAmount = (
  value,
  { placeholder = "-", fixed = 2, prefix = "", suffix = "" } = {}
) => {
  if (value === 0) return amount(0, fixed);
  if (isNaN(value)) return placeholder;
  const _amount = amount(value, fixed);
  return _amount === null ? placeholder : prefix + _amount + suffix;
};

const setTitle = (title) => {
  document.title = title;
  if (isMobile) {
    const iframe = document.createElement("iframe");
    iframe.style.visibility = "hidden";
    const iframeCallback = () => {
      setTimeout(() => {
        iframe.removeEventListener("load", iframeCallback);
        document.body.removeChild(iframe);
      }, 10);
    };
    iframe.addEventListener("load", iframeCallback);
    document.body.appendChild(iframe);
  }
};
// 深度拷贝
export const deepCopy = (obj) => {
  if (typeof obj !== "object") return;
  var newObj = obj instanceof Array ? [] : {};
  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      newObj[key] =
        obj[key] && typeof obj[key] === "object"
          ? deepCopy(obj[key])
          : obj[key];
    }
  }
  return newObj;
};

function trim(str) {
  return str.replace(/(^\s*)|(\s*$)/g, "");
}

/**
 * 默认横杠
 * @param {*} value
 */
export const dash = (value) => {
  if (value == null || trim(value) == "") {
    return "-";
  }
  return value;
};

export const encryptPhone = (phoneNumber) => {
  if (!phoneNumber) return "-";
  return phoneNumber.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
};

export default {
  isIOS,
  isWechat,
  isMobile,
  columnAmount,
  setTitle,
  dash,
  encryptPhone,
};
