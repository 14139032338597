import config from "./config";
import store from "../store";

export const authCheck = (identifier) => {
  // 开发环境拥有所有权限
  if (config.isDevelopment) return true;
  try {
    const u = store.getters.info;
    const rules = (u.resources || [])
      .filter((it) => it.resourceType == "MENU")
      .map((it) => it.resourceIdentifier);
    return Array.isArray(identifier)
      ? identifier.reduce(
          (flag, rule) => (flag = rules.includes(rule) ? true : flag),
          false
        )
      : rules.includes(identifier);
  } catch (error) {
    return false;
  }
};
