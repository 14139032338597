const RequestHeaders = {};

for (const key in process.env) {
  if (/^VUE_APP_REQUEST_HEADER_/.test(key)) {
    const headerName = key.split("VUE_APP_REQUEST_HEADER_").pop();
    const headerValue = process.env[key];
    RequestHeaders[headerName] = headerValue;
  }
}

export default {
  isDevelopment: process.env.NODE_ENV === "development",
  env: process.env.NODE_ENV,
  // API 接口和阿里云 OSS
  baseUrl: process.env.VUE_APP_API_BASE.replace("http:", location.protocol),
  pageSize: 10,
  // 权限验证
  basicAuthPlatform: process.env.VUE_APP_API_BASIC_AUTH_PLATFORM,

  basicAuthUsername: process.env.VUE_APP_API_BASIC_AUTH_USERNAME,
  basicAuthPassword: process.env.VUE_APP_API_BASIC_AUTH_PASSWORD,
  requestHeaders: RequestHeaders,
};
