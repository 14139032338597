import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import filter from "@/common/filter";
import { authCheck } from "@/common/auth";
// import "amfe-flexible/index.js";
import * as vConsole from "vconsole";
if (process.env.NODE_ENV == "development") {
  // eslint-disable-next-line no-new,new-cap
  new vConsole()
}

Vue.use(filter);
Vue.config.productionTip = false;
Vue.prototype.$authCheck = authCheck;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
