<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { fontSizeSet } from "@/common/fontSet";
export default {
  mounted() {
    fontSizeSet(window, document);
  },
};
</script>

<style lang="less">
@import "./styles/index.less";
</style>
