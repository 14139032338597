export const fontSizeSet= (win, doc) =>{
  var docEl = doc.documentElement;
  var docWidth;
  function setRemUnit() {
    var ua = navigator.userAgent;
    var ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
      isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
      isAndroid = ua.match(/(Android)\s+([\d.]+)/),
      isMobile = !!isIphone || !!isAndroid || !!ipad
    if (isMobile) {
      docWidth = docEl.clientWidth;
    } else {
      docWidth = docEl.clientWidth > 640 ? 640 : docEl.clientWidth;
    }
    var rem = docWidth / 10;
    docEl.style.fontSize = rem + 'px';
  }
  win.addEventListener('resize', function () {
    setRemUnit();
  }, false);
  win.addEventListener('pageshow', function (e) {
    if (e.persisted) {
      setRemUnit();
    }
  }, false);
  win.addEventListener('load', function () {
    var $elem = document.getElementById('scrollView')
    scrollFix($elem)
  })

  function scrollFix(elem) {
    elem = elem || document.querySelector(elem);
    if (!elem) return;
    elem.addEventListener('touchstart', function () {
      var startTopScroll = elem.scrollTop;
      if (startTopScroll <= 0) elem.scrollTop = 1;
      if (startTopScroll + elem.offsetHeight >= elem.scrollHeight) {
        elem.scrollTop = elem.scrollHeight - elem.offsetHeight - 1;
      }
    }, false);
  }
  setRemUnit();

  setTimeout(() => {
    setRemUnit();
  }, 500);
}
