import Vue from "vue";
import Vuex from "vuex";
import persistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  getters: {
    token: (state) => state.token,
    info: (state) => state.info,
  },
  state: {
    token: "",
    info: {},
  },
  mutations: {
    setAuthAreas: (state, v) => {
      state.authAreas = v;
    },
    setAuthProvince: (state, v) => {
      state.authProvince = v;
    },
    setAuthMerchants: (state, v) => {
      state.authMerchants = v;
    },
    setAuthProducts: (state, v) => {
      state.authProducts = v;
    },
    setAuthModeIn: (state, v) => {
      state.authModeIn = v;
    },
    setAuthInfo: (state, v) => {
      state.authInfo = v;
    },
    SET_INFO: (state, info) => {
      state.info = info;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    setPublicItemKey: (state, v) => {
      state.publicItemKey = v;
    },
  },
  actions: {
    OnLogin({ commit }, userInfo) {
      commit("SET_INFO", userInfo);
      commit("SET_TOKEN", userInfo.tokenInfo.access_token);
    },
  },
  modules: {},
  plugins: [
    persistedState({
      storage: window.sessionStorage,
    }),
  ],
});
